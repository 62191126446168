import { assertSuccess, paramsParser } from "../core";
import axios from "../core/axios";
import { Headers } from "./generic";
import type {
  AssetCreateBody,
  AssetEditBody,
  AssetEntity,
  AssetListParams,
  ContentBlockCreateBody,
  ContentBlockEditBody,
  ContentBlockListParams,
  CollectionResult,
  MessageResult,
  Result,
  ContentBlockEntity,
  IncludeArr,
} from "../types";
import { nanoid } from "nanoid";

export class Content {
  static async createContentBlock(data: ContentBlockCreateBody, auth: string) {
    const resp = await axios.post<Result<ContentBlockEntity>>(
      "/content/block/create",
      data,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async editContentBlock(
    id: string,
    data: ContentBlockEditBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<ContentBlockEntity>>(
      `/content/block/${id}`,
      data,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteContentBlock(id: string, version: number, auth: string) {
    const resp = await axios.delete<MessageResult>(`/content/block/${id}`, {
      headers: Headers.Bearer(auth),
      data: {
        version,
      },
    });

    return assertSuccess(resp.data).data;
  }

  static async getContentBlock(
    id: string,
    includes?: IncludeArr<ContentBlockEntity>,
    auth?: string,
  ) {
    const resp = await axios.get<Result<ContentBlockEntity>>(
      `/content/block/${id}` + paramsParser({ includes }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getContentBlocks(
    params: ContentBlockListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<ContentBlockEntity>>(
      `/content/block` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async createAsset(data: AssetCreateBody, auth: string) {
    const formData = new FormData();
    formData.append(`file`, data.file, nanoid());

    const resp = await axios.post<Result<AssetEntity>>(
      "/content/asset/create",
      formData,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async editAsset(id: string, data: AssetEditBody, auth: string) {
    const resp = await axios.post<Result<AssetEntity>>(
      `/content/asset/${id}`,
      data,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteAsset(id: string, auth: string) {
    const resp = await axios.delete<MessageResult>(`/content/asset/${id}`, {
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  static async getAsset(id: string, auth?: string) {
    const resp = await axios.get<Result<AssetEntity>>(`/content/asset/${id}`, {
      headers: auth ? Headers.Bearer(auth) : undefined,
    });

    return assertSuccess(resp.data).data;
  }

  static async getAssets(params: AssetListParams = {}, auth?: string) {
    const resp = await axios.get<CollectionResult<AssetEntity>>(
      `/content/asset` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }
}
